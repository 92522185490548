import WhatToExpectSection from 'components/PDP/WhatToExpectSection';
import React from 'react';

import { ImageType } from '../../../utils/types';

export interface WhatToExpectContainerProps {
  content?: {
    title: string;
    sectionId: string;
    headline: string;
    subHeadline: string;
    backgroundColor: 'Primary' | 'Secondary';
    slug: string;
    listOfContentSections: {
      title: string;
      image: ImageType;
      description?: {
        description: string;
      };
    }[];
  };
}

export const WhatToExpectContainer: React.FC<WhatToExpectContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  const {
    title,
    headline,
    subHeadline,
    listOfContentSections,
    backgroundColor,
    slug,
  } = content;
  const formattedListOfContent = listOfContentSections.map((item) => ({
    heading: item.title,
    description: item.description?.description || '',
    image: item.image.gatsbyImageData,
    imageAlt: item.image.description || '',
  }));

  return (
    <WhatToExpectSection
      title={title}
      subHeadline={subHeadline}
      headline={headline}
      listOfContent={formattedListOfContent}
      backgroundColor={backgroundColor || 'Primary'}
      uid={slug}
    />
  );
};

export default WhatToExpectContainer;
