import {
  InfoBlock as BaseInfoBlock,
  size,
  mediaQueries,
  typography,
  colors,
  H2,
  H3,
  Button as BaseButton,
} from '@everlywell/leaves';
import { animated as A } from 'react-spring';
import styled from 'styled-components';

import { SectionTitle as RawSectionTitle } from '../../Enterprise/SectionTitle';

const SectionTitle = styled(RawSectionTitle)`
  color: ${colors.green4};
  text-align: center;
`;

const Headline = styled(H3)`
  margin: ${size.xs1}px 0 ${size.md}px;
  font-weight: ${typography.weight.book};

  ${mediaQueries.forTabletHorizontalUp} {
    margin: 0 0 ${size.md}px;
  }
`;

const SubHeadline = styled.p`
  margin: ${size.xs1}px 0 ${size.xl1}px;
  font-size: ${typography.bodyText};
  font-weight: ${typography.weight.light};

  ${mediaQueries.forTabletHorizontalUp} {
    margin: 0 0 ${size.xl4}px;
  }
`;


const InfoBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
`;

const AnimatedBlockContainer = styled(A.div)`
  flex: 1 1 50%;
  max-width: 540px;
`;

const InfoBlock = styled(BaseInfoBlock).attrs((props) => ({
  imageAlt: props.imageAlt,
}))`
  margin-bottom: ${size.xl2}px;
  align-items: center;

  div div {
    height: unset;
    background-color: unset;
  }

  p {
    font-weight: ${typography.weight.book};
    font-size: ${typography.bodyText};
    max-width: 281px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl4}px;
    align-items: flex-start;

    h3 {
      margin-left: ${size.xl2}px;
    }

    p {
      margin-left: ${size.xl2}px;
    }
  }
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(BaseButton)`
  border-color: ${colors.green5};
  color: ${colors.green5};
  &:hover,
  &:focus {
    background-color: ${colors.green5};
  }
`;

export {
  SectionTitle,
  Headline,
  SubHeadline,
  InfoBlockContainer,
  AnimatedBlockContainer,
  InfoBlock,
  CtaContainer,
  Button,
};
